<template>
  <el-container>
    <el-row style="width: 100%">
      <el-col :span="24">
        <el-form ref="form" :model="form" label-width="100px" label-position="right" size="mini">
          <el-form-item label="标题" prop="title">
            <el-input v-model="form.title" placeholder="请填写文章标题"></el-input>
          </el-form-item>
          <el-form-item label="内容" prop="content">
            <quill-editor v-model="form.content" ref="quill" :options="editorOption"></quill-editor>
            <el-upload :http-request="change" action="" :show-file-list="false">
              <el-button size="small" id="uploadImg" type="primary" style="display: none">点击上传</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item style="float:right">
            <el-button type="danger" @click="onDelete" v-if="form.guid !== ''">删除</el-button>
            <el-button type="primary" @click="onSubmit">保存</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </el-container>
</template>

<script>
import { Quill } from 'vue-quill-editor'
import ImageResize from 'quill-image-resize-module'
import {container, ImageExtend} from 'quill-image-extend-module'
// import {container, ImageExtend, QuillWatch} from 'quill-image-extend-module'

Quill.register('modules/ImageExtend', ImageExtend)
Quill.register('modules/imageResize', ImageResize)
export default {
  data() {
    return {
        userInfo: {},
        form: { 
            content: "",
            createBy: "",
            createTime: "",
            guid: "",
            title: "",
            updateBy: "",
            updateTime: ""
        },
        editorOption: {
          modules: {
              toolbar: {  // 如果不上传图片到服务器，此处不必配置
                  container: container,  // container为工具栏，此次引入了全部工具栏，也可自行配置
                  handlers: {
                    'image': function() {  // 劫持原来的图片点击按钮事件
                        document.getElementById('uploadImg').click()
                    }
                  }
              },
              imageResize: {
                displayStyles: {
                  backgroundColor: 'black',
                  border: 'none',
                  color: 'white'
                },
                modules: ['Resize', 'DisplaySize', 'Toolbar']
              },
              ImageExtend: {
                loading: true,
                name: 'img',
                action: '/api/common/image/upload/',
                response: (res) => {
                  return res.data.data[0]
                }
              },
            },
            placeholder: '请填写文章内容'
        }
    }
  },
  created() {
    let self = this;
    self.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    self.getReportDetail(self.$route.query.guid || null)
  },
  methods: {
    // 获取文章详情
    getReportDetail(guid) {
      let self = this;
      if (guid) {
        self.$axios
          .get("/api/help-article/get/" + guid, {
            headers: { token: self.userInfo.token },
          })
          .then(function(response) {
            self.form.guid = response.data.data.guid;
            self.form.content = response.data.data.content;
            self.form.createBy = response.data.data.createBy;
            self.form.createTime = response.data.data.createTime;
            self.form.title = response.data.data.title;
            self.form.updateBy = response.data.data.updateBy; 
            self.form.updateTime = response.data.data.updateTime; 
          })
          .catch(function(error) {
            self.$message({
              message: error.response.data.message,
              type: "warning",
            });
          });
      }
    },
    // 保存
    onSubmit() {
      let self = this;
      self.$refs["form"].validate((valid) => {
        if (valid) {
          let data = JSON.parse(JSON.stringify(self.form));
          self.$axios
            .post("/api/help-article/saveOrUpdate", data, {
              headers: {
                "content-type": "application/json",
                token: self.userInfo.token,
              },
            })
            .then(function() {
              self.$message({
                message: "操作成功",
                type: "success",
              });
              self.$router.push({ name: "helpCenterReportList" });
            })
            .catch(function(error) {
              self.$message({
                message: error.response.data.message,
                type: "warning",
              });
            });
        } else {
          self.$message({
            message: "请输入正确的管理员信息",
            type: "warning",
          });
          return false;
        }
      });
    },
    // 删除
    onDelete() {
      let self = this;
      self
        .$confirm("确定删除 " + (self.form.title || "未命名用户") + "？")
        .then(() => {
          self.$axios
            .delete("/api/help-article/delete", {
              data: { guid: self.form.guid },
              headers: { token: self.userInfo.token },
            })
            .then(() => {
              self.$message({
                message: "删除成功",
                type: "success",
              });
              self.$router.push({ name: "helpCenterReportList" });
            })
            .catch((error) => {
              self.$message({
                message: error.response.data.message,
                type: "warning",
              });
            });
        })
        .catch(() => {});
    },
    change(e) {
        // console.log(1);
      console.log(e);
      let img = new FormData()
      img.append('imageArray', e.file)
      this.$axios
        .post("/api/common/image/upload/", img ,{
        headers: { token: this.userInfo.token,'Content-Type':'multipart/form-data'}
        })
        .then(res => {
          let quill = this.$refs.quill.quill
            // const formdata = _.extend({}, this.formdata)
            let length = quill.getSelection().index//光标位置
            // 插入图片  图片地址
            quill.insertEmbed(length, 'image', res.data.data[0])
            // 调整光标到最后
            quill.setSelection(length + 1)//光标后移一位
        })
        .catch(err => {
          console.error(err)
        })
    }
  }
};
</script>

<style>
  .el-transfer-panel{
  width: 30%;
}
</style>
